<template>
  <div class="task-root-container">
    <div class="task-header-container">
      <div class="task-header-left-container">
        <span class="task-header-title" style="margin-left:60px;">任务中心</span>
      </div>
      <img @click="close" :src="icons.upArrow" class="employer-arrow" />
    </div>
    <div class="task-main-container">
      <div class="task-menu-container">
        <div class="task-menu-big-item-container">
          <img :src="icons.taskTracker" />
          <span class="task-menu-big-item-title">任务追踪器</span>
        </div>
        <div
          @click="navigate('task-dashboard')"
          :class="['task-menu-small-item-container',currentMenu=='task-dashboard'?'task-menu-small-item-container-selected':null]"
        >
          <img :src="icons.taskDashboard" />
          <span class="task-menu-small-item-title">全部看板</span>
        </div>
        <div
          @click="navigate('task-archive')"
          :class="['task-menu-small-item-container',currentMenu=='task-archive'?'task-menu-small-item-container-selected':null]"
        >
          <img :src="icons.archive" />
          <span class="task-menu-small-item-title">归档的任务</span>
        </div>

        <div class="task-menu-big-item-container">
          <img :src="icons.milestone" />
          <span class="task-menu-big-item-title">里程碑事件</span>
        </div>
        <div
          @click="navigate('task-newmilestone')"
          :class="['task-menu-small-item-container',currentMenu=='task-newmilestone'?'task-menu-small-item-container-selected':null]"
        >
          <img :src="icons.milestoneNew" />
          <span class="task-menu-small-item-title">创建新的</span>
        </div>
        <div
          @click="navigate('task-milestone')"
          :class="['task-menu-small-item-container',currentMenu=='task-milestone'?'task-menu-small-item-container-selected':null]"
        >
          <img :src="icons.milestoneList" />
          <span class="task-menu-small-item-title">里程碑列表</span>
        </div>
      </div>
      <div class="task-right-container">
        <div class="task-right-header-container">
          <span class="task-right-title">#{{projectName}}# {{title}}</span>
          <span style="font-size:23px;color:#777777" v-if="currentMenu == 3">(作为付款方)</span>
        </div>
        <transition name="fade" mode="out-in">
          <div class="router-scroll">
            <router-view class="task-router-container" />
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../common/icon";
export default {
  data() {
    return {
      icons: icon,
      projectId: 0,
      projectName: "礼信北京项目（华北区）",

      title: "",
      currentMenu: 1,
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;

    this.projectGetBasicAction({
      employer_id: localStorage.getItem("employer_id"),
      project_id: this.projectId,
    })
      .then((res) => {
        this.projectName = res.name;
      })
      .catch((error) => {
        this.$Message.error(error);
      });

    // 刷新页面后控制选中
    this.currentMenu = this.$router.currentRoute.name;
    switch (this.currentMenu) {
      case "task-dashboard":
        this.title = "任务看板";
        break;
      case "task-archive":
        this.title = "归档的任务";
        break;
      case "task-newmilestone":
        this.title = "创建新的里程碑事件";
        break;
      case "task-milestone":
        this.title = "里程碑事件";
        break;
    }
  },
  methods: {
    ...mapActions({
      projectGetBasicAction: "projectGetBasic",
    }),
    close() {
      this.$router.push({
        name: "project-base",
        params: {
          id: this.projectId,
        },
      });
    },
    navigate(menu) {
      if (this.currentMenu == menu) {
        return;
      }
      this.currentMenu = menu;
      this.$router.push({
        name: menu,
        params: {
          id: this.projectId,
        },
      });
      switch (menu) {
        case "task-dashboard":
          this.title = "任务看板";
          break;
        case "task-archive":
          this.title = "归档的任务";
          break;
        case "task-newmilestone":
          this.title = "创建新的里程碑事件";
          break;
        case "task-milestone":
          this.title = "里程碑事件";
          break;
      }
    },
  },
};
</script>

<style scoped>
.task-root-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #f9f9f9;
  overflow: hidden;
}
.task-header-container {
  background-color: #313f4a;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 50px;
  padding-left: 50px;
}
.task-header-left-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.task-arrow {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.task-header-title {
  color: #fff;
  font-size: 15px;
}
.task-router-container {
  flex: 1;
}
.task-main-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
}
.task-menu-container {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 150px 100px 0px 0px;
}
.task-right-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.task-right-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 150px;
}
.task-menu-big-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
}
.task-menu-big-item-title {
  font-size: 15px;
  font-weight: bold;
  margin-left: 10px;
}
.task-menu-small-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px;
  padding-left: 10px;
  cursor: pointer;
}
.task-menu-small-item-container-selected {
  border-left: 3px solid #000000;
}
.task-menu-small-item-title {
  font-size: 12px;
  margin-left: 5px;
}
.task-right-title {
  font-size: 32px;
  font-weight: bold;
}
.router-scroll {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
</style>